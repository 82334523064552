@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center; 
}

.custom-btn {
  box-shadow: 0px 2.9566px 2.9566px rgba(0, 0, 0, 0.25), inset 0px 1.4783px 2.9566px rgba(255, 255, 255, 0.25), inset 0px -1.4783px 2.9566px rgba(0, 0, 0, 0.25);
}

